@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-rgb: 0, 0, 0;
  }
}


/* body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
} */



.animation-duration-25s {
  animation-duration:25s !important;
}
.animation-duration-15s {
  animation-duration: 15s !important;
}
.primary-rolling-animation {
  width: inherit;
  height: inherit;
  animation: primary-rolling-animation 15s linear infinite;
}

.secondary-rolling-animation {
  width: inherit;
  height: inherit;
  animation: secondary-rolling-animation 15s linear infinite;
}


@keyframes primary-rolling-animation {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

@keyframes secondary-rolling-animation {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}


@font-face {
  font-family: 'iran-yekan';
  src: url('/fonts/iranyekanweblight.ttf') format('truetype');
	font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'iran-yekan';
  src: url('/fonts/iranyekanwebregular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'iran-yekan';
  src: url('/fonts/iranyekanwebbold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


 

[dir="rtl"] * {
  font-family:  'iran-yekan', sans-serif;
}

.default-font {
  font-family:  sans-serif !important;
}
